import Model from '../model'
import Util from '../util'

export default class {
  constructor(element, config){
  	this.modal = $(element);
  	this.opacity = 0.6;
  	this.button = $(".close_modal");
  	this.limit = 120;
  	this.cookie = Util.getCookie('modal')
    this.init()
  }
  init(){
  	if(cookie !== "off"){
  		this.overlay = $("<div></div>");
  		overlay.css({
  			"position":"fixed",
  			"z-index":100,
  			"top":0,
  			"left":0,
  			"height":100+"%",
  			"width":100+"%",
  			"background":"#000",
  			"opacity":opacity
  		});
  		$("body").append(overlay);
  		modal.css("display", "block");
  	}
    this.bindEvents()
  }
  bindEvents(){
  	button.click(function(){
  		$(overlay).fadeOut("slow");
  		$(modal).hide();
  		this.clearTime = new Date();
  		clearTime.setTime(clearTime.getTime()+(limit*60*1000));
  		Util.setCookie('modal', 'off', clearTime)
  	});
  	$(".remove_cookie").click(function(){
      Util.setCookie('modal', 'on', clearTime)
  		location.reload();
  	});
  }
}
