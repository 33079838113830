import Model from '../model'

// -----------------------------------------------------------------
//
// @method Module.AddUaClass()
// デバイスに応じてhtmlタグにUAに応じてクラスを付与
// -----------------------------------------------------------------

export default class {
  constructor(){
    this.init()
  }
  init() {
    let htmlNode = document.getElementsByTagName('html')[0];
    if(Model.info.ua.lteA4) {
      htmlNode.classList.add('is-android4')
    }
    if(Model.info.ua.lteA5) {
      htmlNode.classList.add('is-android5')
    }
    if(Model.info.ua.AndroidLegacy) {
      htmlNode.classList.add('is-androidlegacy')
    }
    if(Model.info.ua.Tablet) {
      htmlNode.classList.add('is-tablet')
    }
    if(Model.info.IE.IE11) {
      htmlNode.classList.add('is-IE')
    }
  }


}
