import Model from '../model'

export default class {
  constructor(element){
    this.element = element
    if(Model.info.IE.IE11 || Model.info.IE.Edge) this.init()
  }
  init(){
    this.target = this.element.querySelectorAll('*')
    this.bindEvents()
  }

  // -----------------------------------------------------------------
  //
  // @style Selector:focus-widhin
  // focus-within IE対応
  // -----------------------------------------------------------------

  bindEvents(){
    for(var target of this.target) {
      target.addEventListener('focus', ()=>{
        this.element.classList.add('is-focus-within')
      })
      target.addEventListener('blur', ()=>{
        this.element.classList.remove('is-focus-within')
      })
    }
  }

}
