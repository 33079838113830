import Model from '../model'

export default class {
  constructor(){
    this.init()
  }
  init(){
    window.addEventListener('load', ()=> {
      this.url = $(location.hash)
      if(this.url[0]) this.scroll()
    })
  }
  scroll(){
    let target = this.url.offset().top,
        position = (Model.state.scroll.top > target) ? target - 100 : target
    $('body,html').animate({scrollTop: position}, 10)
    return false
  }

}
