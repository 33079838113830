import Util from '../util/'

export default class {
  constructor(element, config){
    this.element = element
    this.config = (config) ? config : null
    this.init()
  }
  bindEvents(){
    this.element.addEventListener('click', ()=>{
    })
  }
  init(){
    $.getScript("//ssl-cache.stream.ne.jp/www50/eqa006vnbb/jmc_pub/jmc_swf/player/t3/obj.js", ()=>{
      this.play()
    })
    // this.request = new XMLHttpRequest()
    // this.request.open('GET', Util.escapeHTMLliteral`//ssl-cache.stream.ne.jp/www50/eqa006vnbb/jmc_pub/jmc_swf/player/t3/obj.js`, false)
    // this.request.send(null)
    // if (this.request.status === 200) {
      // this.base = document.createElement('script')
      // this.base.text = this.request.responseText
      // this.element.appendChild(this.base)
      // this.play()
    // }
    // this.src = document.createElement('script')
    // this.src.src = Util.escapeHTMLliteral`//ssl-cache.stream.ne.jp/www50/eqa006vnbb/jmc_pub/jmc_swf/player/t3/obj.js`
    // this.element.appendChild(this.src)
    this.bindEvents()
  }
  play(){
    // console.log(this.config)
    // jstream_t3.PlayerFactoryOBJ.create(this.config)

    this.script = document.createElement('script')
    this.script.innerHTML = Util.escapeHTMLliteral`jstream_t3.PlayerFactoryOBJ.create(${JSON.stringify(this.config)})`
    // setTimeout(()=>{
    //   this.element.appendChild(this.script)
    //   console.log(this.element);
    //   console.log('test');
    // }, 3000)

    // console.log(window)
  }
}
