import Model from '../model'
import Util from '../util'

export default class {
  constructor(element, config){
    this.element = element
    this.$el = {
      audio: $(this.element).find('.r-radio')
    }
    this.init()
    this.bindEvents()
  }
  bindEvents(){
    $(this.element).on('click', '.c-modal__bg, [data-role="modal-close"]', ()=>{
      //クリック時のイベント
      this.$el.audio.each(function() {
        this.pause()
        this.currentTime = 0;
      })
    })
    Model.event.on('ON_WINDOW_RESIZE', ()=>{
      //リサイズ時のイベント
    })
  }
  init(){

  }
  fuga(){
  }
}
