export default class {
  constructor(element, config){
    this.el = {
      nav: element,
      button : element.querySelectorAll('[data-role=toggle-recipes-btn]'),
      contents : element.nextElementSibling.querySelectorAll('[data-role*=toggle-recipes-content]'),
      target: undefined,
      close : element.nextElementSibling.querySelectorAll('[data-role=close-recipes-btn]')
    }
    this.init()
  }
  init(){
    for(let i = 0; i < this.el.button.length; i = i+1) {
      this.el.button[i].addEventListener('click', (e)=>{
        this.toggle(i)
      })
      this.el.close[i].addEventListener('click', (e)=>{
        this.close()
      })
    }
  }
  toggle(i){
    this.el.target = (this.el.button[i].getAttribute('data-target')) ? '-' + this.el.button[i].getAttribute('data-target') : ''
    let isOpen = this.el.button[i].getAttribute('aria-expanded')

    for(let i = 0; i < this.el.button.length; i = i+1) {
      this.el.button[i].setAttribute('aria-expanded', 'false')
    }
    for(let i = 0; i < this.el.contents.length; i = i+1) {
      this.el.contents[i].setAttribute('aria-hidden', 'true')
    }
    if(isOpen === 'true') {
      this.el.button[i].setAttribute('aria-expanded', 'false')
      this.el.nav.nextElementSibling.querySelector('[data-role=toggle-recipes-content' + this.el.target + ']').setAttribute('aria-hidden', 'true')
    } else {
      this.el.button[i].setAttribute('aria-expanded', 'true')
      this.el.nav.nextElementSibling.querySelector('[data-role=toggle-recipes-content' + this.el.target + ']').setAttribute('aria-hidden', 'false')
    }
  }
  close(){
    for(let i = 0; i < this.el.button.length; i = i+1) {
      this.el.button[i].setAttribute('aria-expanded', 'false')
    }
    for(let i = 0; i < this.el.contents.length; i = i+1) {
      this.el.contents[i].setAttribute('aria-hidden', 'true')
    }
  }
}
