import Util from '../util'

export default class {
  constructor(element, config){
    this.el = {
      button : element.querySelectorAll('[role=button]')
    }
    this.config = (config) ? config : null
    this.type = (Util.isKeyInObject(this.config,'type')) ? this.config['type'] : null
    this.bindEvents()
  }
  isopen(e){
    let isOpen = e.getAttribute('aria-expanded')
    if(isOpen === 'true') {
      e.setAttribute('aria-expanded', 'false')
    } else {
      e.setAttribute('aria-expanded', 'true')
    }
  }
  bindEvents(){
    for(let i = 0; i < this.el.button.length; i = i + 1) {
      if(this.type !== null) {
        this.el.button[i].addEventListener('click', (e)=>{
          this.isopen(e.currentTarget)
        })
      } else {
        this.el.button[i].previousElementSibling.addEventListener('click', (e)=>{
          this.isopen(e.currentTarget.nextElementSibling)
        })
      }
    }
  }
}
