import Model from '../model'

export default class {
  constructor(element, config){
    this.$el = {
      tabs: $(element)
    }
    this.$el.tabpanel = this.$el.tabs.find('[role=tabpanel]')
    this.$el.tab = this.$el.tabs.find('[role=tab]')
    // if(Model.info.isSP) this.$el.tabpanel.css('opacity',1)
    this.bindEvents()
    
  }
  bindEvents(){
    this.$el.tab.on('click', (e)=>{
      this.number = e.currentTarget.getAttribute('aria-controls')
      if (Model.info.isSP && Model.info.ua.Tablet) {
        this.tabSwitch($(e.currentTarget))
      } else if(Model.info.isSP) {
        this.accordion($(e.currentTarget))
      } else {
        this.tabSwitch($(e.currentTarget))
      }
    })
    Model.event.on('ON_WINDOW_RESIZE', ()=>{
      if(!Model.info.isSP) {
        if(Model.info.ua.Tablet && this.w === document.documentElement.clientWidth) return false
        this.number = this.$el.tab[0].getAttribute('aria-controls')
        this.tabSwitch(this.$el.tab.eq(0))
      }
    })
  }
  tabSwitch(e){
    if(Model.info.ua.Tablet) this.w = document.documentElement.clientWidth
    this.$el.tab.removeClass('is-active')
    e.addClass('is-active')
    this.$el.tabpanel.attr('aria-hidden', 'true')
    $('[aria-labeledby=' + this.number + ']').attr('aria-hidden', 'false')
  }
  accordion(e){
    if(e.hasClass('is-active')) {
      $('[aria-labeledby=' + this.number + ']').attr('aria-hidden', 'true')
      e.removeClass('is-active')
    } else {
      $('[aria-labeledby=' + this.number + ']').attr('aria-hidden', 'false')
      e.addClass('is-active')
    }
  }
}
