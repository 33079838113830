import Model from '../model'

export default class {
  constructor(element, config){
    this.element = element
    this.config = (config) ? config : null
    this.url = undefined
    this.data = undefined
    this.o = {}
    this.el = {
      button: undefined
    }
    this.init()
  }
  init(){
    this.el = {
      button: this.element.querySelector('[data-role=submit-btn]'),
      compArea: this.element.querySelector('[data-role=comp-area]'),
      errArea: this.element.querySelector('[data-role=err-area]'),
      mailAddressArea: this.element.querySelector('[data-role=mailaddress-err]'),
      communicationArea: this.element.querySelector('[data-role=communication-err]')
    }
    this.url = this.element.getAttribute('action')
    this.data = this.element.querySelectorAll('input')
    this.bindEvents()
  }
  bindEvents(){
    this.element.addEventListener('submit', (e)=>{
      e.preventDefault()
      this.reset()
      this.element.classList.add('is-sending')
      for(let i=0; i < this.data.length; i=i+1) {
        this.data[i].disabled = true
      }
      this.get()
      this.submit()
    }, false)
    Model.event.on('ON_CLOSE_MODALWINDOW', ()=>{
      this.reset()
    })
  }
  reset(){
    console.log('reset');
    this.element.classList.remove('is-err')
    this.el.mailAddressArea.classList.add('u-none')
    this.el.communicationArea.classList.add('u-none')
    this.el.compArea.setAttribute('aria-hidden', 'true')
    this.el.errArea.setAttribute('aria-hidden', 'true')
  }
  get() {
    this.o = {}
    for(let i=0; i < this.data.length; i=i+1) {
      let key = this.data[i].getAttribute('name')
      this.o[key] = this.data[i].value
    }
  }
  submit(){
    $.ajax({
      type: "POST",
      url: this.url,
      data: this.o
    })
    .then(
      response=>{
        this.response = response
        if(response.result === 'OK') {
          this.comp()
        } else {
          this.el.mailAddressArea.classList.remove('u-none')
          this.err()
        }
      },
      fail=>{
        this.el.communicationArea.classList.remove('u-none')
        this.err()
      }
    )
    .done(
      comp=>{
        this.element.classList.remove('is-sending')
        for(let i=0; i < this.data.length; i=i+1) {
          this.data[i].disabled = false
        }
      }
    )
  }
  comp(){
    this.el.compArea.setAttribute('aria-hidden', 'false')
    this.el.errArea.setAttribute('aria-hidden', 'true')
  }
  err(){
    this.el.compArea.setAttribute('aria-hidden', 'true')
    this.el.errArea.setAttribute('aria-hidden', 'false')
    this.element.classList.add('is-err')
  }
}
