import ScrollHeader from './scroll-header'
import ScrollPageTop from './scroll-pagetop'
import ScrollPageTopKewpie from './scroll-pagetop-kewpie'
import HeaderProductsMenu from './header-products-menu'
import FocusWithIn from './focus-within'

import IsCurrentLocalnavi from './is-current-localnavi'
import IsCurrentFooternavi from './is-current-footernavi'
import IsCurrentGlobalnavi from './is-current-globalnavi'
import LocalnaviAccodionmenu from './localnavi-accordionmenu'

import ChangeRowtable from './change-rowtable'
import ChangeRowtableType2 from './change-rowtable-type2'
import ReplaceDeviceimage from './replace-deviceimage'

import ToggleBtn from './toggle-btn'
import SwitchTabs from './switch-tab'
import SwitchTabAccodion from './switch-tab-accordion'

import ValidateInput from './validate-input'

import ExpandAccordion from './expand-accordion'
import FilterPulldown from './filter-pulldown'
import MainvisualCarousel from './mainvisual-carousel'
import DetailCarousel from './detail-carousel'
import ListCarousel from './list-carousel'
import ListPagerFilter from './list-pager-filter'
import ModalWindow from './modal-window'
import RenderNewsList from './render-news-list'
import RenderNewsListSimple from './render-news-list-simple'
import RenderNoticesList from './render-notices-list'
import ScrollFadeinAmimation from './scroll-fadein-animation'
import AdjustCardListPickup from './adjust-card-list-pickup'
import RenderProductsOthercategory from './render-products-othercategory'

import layerBoard from './layer-board'
import PrintButton from './print-button'
import PostFormData from './post-formdata'
import GetJstreamThumbnail from './get-jstream-thumbnail'
import JStreamPlayer from './jstream-player'
import StopAudios from './stop-audio'

import WindowOpen from './window-open'
import HeightLine from './height-line'
import OverflowScroll from './overflow-scroll'
import ToggleRecipesNav from './toggle-recipes-nav'
import ShowmoreRecipesTag from './showmore-recipes-tag'
import FilterSelected from './filter-selected'


export default class {
  constructor(){
    this.module = document.querySelectorAll('[data-module]')
    this.moduleKeys()
  }
  moduleKeys(){
    Array.prototype.forEach.call(this.module, element => {
      const MODULE_KEY = element.getAttribute('data-module')
      const MODULE_CONF = element.getAttribute('data-config') || null
      const Components = require(`./${MODULE_KEY}`).default
      const CONFIG = (this.isJSON(MODULE_CONF)) ? JSON.parse(MODULE_CONF) : (MODULE_CONF) ? MODULE_CONF : null
      if (Components !== void 0)
        return new Components(element, CONFIG)
      })
  }
  isJSON(arg) {
    return /{/.test(arg)
  }
}
