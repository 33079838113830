import Model from '../model'
import Util from '../util'

// モーダル時動画コントロール
// type: Array
window.jsteamModalMovieObject = []

export default class {
  constructor(element, config){
    this.element = element
    this.config = config
    this.isShowLoaded = undefined
    this.lang = 'jp'
    this.$el = {
      modal: $('[data-role=modal-window-section]'),
      section: $('[data-role=modal-window-section] [data-modalsection]'),
      bg: $('.c-modal__bg'),
      closebtn: $('.c-modal__closebtn'),
      close: undefined,
      target: undefined
    }
    this.modalWindowOpenFlag = false
    this.closebtnType = undefined
    this.modalMovieContent = window.jsteamModalMovieObject
    this.init()
    this.bindEvents()
  }
  init(){
    this.$el.target = $('[data-modalsection=' + this.element.getAttribute('data-target') + ']')

    //グローバル用
    if(Util.isKeyInObject(this.config,'lang')) this.lang = this.config['lang']

    if(Util.isKeyInObject(this.config,'device')) {
      if(this.config['device'] === 'pc') this.element.classList.add('u-clickdisable--sp')
      if(this.config['device'] === 'sp') this.element.classList.add('u-clickdisable--pc')
    }
    if(Util.isKeyInObject(this.config,'closebtn')) {
      this.closebtnType = this.config['closebtn']
    }
    if(Util.isKeyInObject(this.config,'isShowLoaded')) {
      this.isShowLoaded = this.config['isShowLoaded']
    }

    if(this.$el.target[0] && !this.$el.target.find('[data-role=modal-close-bottom]')[0] && this.closebtnType !== 'simple') this.render()
  }
  bindEvents(){
    this.element.addEventListener('click', (e)=>{
      if(Util.isKeyInObject(this.config,'isCurrentCancel')) {
        e.preventDefault()
        if(location.pathname.match(this.config['isCurrentCancel'])) {
          location.href = this.element.getAttribute('href')
        }
      }
      this.openModalWindow()
    })

    if(this.isShowLoaded && Util.getParam()['isshowmodal']) {
      this.openModalWindow()
    }
    Model.event.on('ON_WINDOW_RESIZE', ()=>{
      if(this.modalWindowOpenFlag) this.adjustCloseIcon()
    })
    Model.event.on('ON_ORIENTATION_CHANGE', ()=>{
      if(Model.info.ua.iOS && Model.state.orientation.portrait) this.closeModalWindow()
    })
  }
  render(){
    let closeDOM = document.createElement('div')
    closeDOM.classList.add('c-btn-list', 'r-1line', 'r-center01', 'u-mt-s--pc')
    closeDOM.setAttribute('data-role', 'modal-close-bottom')
    let closeDOMInner = document.createElement('div')
    closeDOMInner.classList.add('c-btn-list__itm')
    let closeDOMInnerSec = document.createElement('p')
    closeDOMInnerSec.classList.add('c-btn-list__itm__link')
    closeDOMInnerSec.setAttribute('data-role', 'modal-close')
    let closeDOMTxt = document.createElement('span')
    closeDOMTxt.classList.add('c-btn-list__itm__content')
    closeDOMTxt.textContent = '閉じる'
    closeDOMInnerSec.appendChild(closeDOMTxt)
    closeDOMInner.appendChild(closeDOMInnerSec)
    closeDOM.appendChild(closeDOMInner)
    this.$el.target[0].appendChild(closeDOM)
  }
  openModalWindow(){
    this.modalWindowOpenFlag = true

    this.$el.target = $('[data-modalsection=' + this.element.getAttribute('data-target') + ']')
    this.$el.modal = this.$el.target.closest('[data-role=modal-window-section]')
    this.$el.section = this.$el.modal.find('[data-modalsection]')

    this.$el.bg = this.$el.modal.find('.c-modal__bg')
    this.$el.closebtn = this.$el.modal.find('.c-modal__closebtn')
    this.$el.close = this.$el.modal.find('[data-role=modal-close]')

    this.$el.target.attr('aria-hidden', 'false')
    this.$el.modal.attr('aria-hidden', 'false')

    if(Util.isKeyInObject(this.config,'width')) {
      this.$el.target.css('width', this.config['width'])
    }
    if(Util.isKeyInObject(this.config,'is-fixed')) {
      if(this.config['is-fixed'] !== 'true') {
        this.$el.modal.addClass('is-absolute')
        this.$el.target.css('top', Model.state.scroll.top + (100 / 750 * 1000))
      } else {
        this.$el.modal.addClass('is-fixed')
        this.$el.target.css('top', '50%')
      }
    } else {
      this.$el.modal.addClass('is-absolute')
      this.$el.target.css('top', Model.state.scroll.top + (100 / 750 * 1000))
    }

    this.adjustCloseIcon(true)

    this.$el.close.on('click', (e)=>{
      this.closeModalWindow()
    })
    this.$el.bg.on('click', (e)=>{
      this.closeModalWindow()
    })
  }
  adjustCloseIcon(isFirst){
    if(Util.isKeyInObject(this.config,'is-fixed')) {
      if(this.config['is-fixed'] !== 'true') {
        if(isFirst) this.$el.closebtn.css('top', Model.state.scroll.top + (100 / 750 * 850))
      } else {
        this.$el.closebtn.css('top', (Model.state.windowsize.h - this.$el.target.innerHeight() - 40) / 2 )
      }
    } else {
      if(isFirst) this.$el.closebtn.css('top', Model.state.scroll.top + (100 / 750 * 850))
    }
    let baseWidth = (!Model.info.isSP && Model.state.windowsize.w < 1040) ? (Model.info.ua.Tablet) ? 1200 : 1080 : Model.state.windowsize.w
    this.$el.closebtn.css('right', (baseWidth - this.$el.target.innerWidth()) / 2 )
  }
  closeModalWindow(){
    this.modalWindowOpenFlag = false
    Model.onCloseModalWindow()
    if(this.modalMovieContent.length > 0) this.pauseMovie()
    this.$el.modal.removeClass('is-absolute')
    this.$el.modal.removeClass('is-fixed')
    this.$el.closebtn.css({'margin-top': 0, 'top': ''})
    this.$el.section.attr('aria-hidden', 'true').css('top','')
    this.$el.modal.attr('aria-hidden', 'true')
    this.$el.close.off('click')
    this.$el.bg.off('click')
    return false
  }
  pauseMovie(){
    for(let i = 0; i < this.modalMovieContent.length; i=i+1){
      this.modalMovieContent[i].accessor.pause()
    }
  }
}
