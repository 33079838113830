import Model from '../model'
import Util from '../util'

export default class {
  constructor(element, config){
    this.element = element
    this.config = (config) ? config : null
    this.type = (Util.isKeyInObject(this.config,'type')) ? this.config.type : false
    this.o = {
      btn: undefined,
      point: undefined,
      contentHeight: undefined
    }
    this.bindEvents()
    this.init()
  }
  init(){
    this.o = {
      btn: this.element,
      point: Model.state.scroll.bottom,
      contentHeight: undefined
    }
    this.scrollEvent()
  }
  bindEvents(){
    Model.event.on('ON_SCROLL', ()=>{
      this.scrollEvent()
    })
  }
  scrollEvent(){
    this.o.contentHeight = document.body.clientHeight - document.querySelector('.l-footer__copy').clientHeight
    if(Model.state.scroll.bottom > this.o.contentHeight) {
      this.o.btn.classList.remove('is-fixed');
    } else {
      this.o.btn.classList.add('is-fixed');
    }
    if(Model.state.scroll.moving) {
      this.o.btn.classList.add('is-moving');
    } else {
      this.o.btn.classList.remove('is-moving');
    }
    if(this.type !== 'initdisplay') {
      if(Model.state.scroll.top < 100) {
        this.o.btn.classList.add('is-hide');
      } else {
        this.o.btn.classList.remove('is-hide');
      }
    }
  }
}
