import Model from '../model'
import Util from '../util'

export default class {
// -----------------------------------------------------------------
//
// @method Components.expandAccordion()
// pattern属性にもとづいて入力を制御する
// -----------------------------------------------------------------
  constructor(element,config) {
    this.element = element
    this.config = config
    this.$el = {
      btn: undefined
    }
    this.init()
  }

  init() {
    this.$el = {
      btn: $(this.element).find('[aria-expanded]'),
      content: $(this.element).find('[aria-hidden]'),
      hash: $(this.element).find(location.hash)
    }
    this.device = (Util.isKeyInObject(this.config,'device')) ? this.config['device'] : 'common'

    if(Model.info.isSP) this.$el.btn.next().css('opacity',1)
    if(this.$el.hash[0]) this.show(this.$el.hash[0])
    if(this.device !== 'common') this.devicePattern()
    this.bindEvents()
  }
  bindEvents() {
    this.$el.btn.on('click', (e)=>{
      this.toggle(e.target)
    })
    Model.event.on('ON_WINDOW_RESIZE', ()=>{
      if(this.device !== 'common' && !Model.state.windowsize.direction.vertical) this.devicePattern()
    })
  }
  toggle(e){
    if(e.getAttribute('aria-expanded') === 'true') {
      e.setAttribute('aria-expanded', 'false')
      e.nextElementSibling.setAttribute('aria-hidden', 'true')
    } else {
      e.setAttribute('aria-expanded', 'true')
      e.nextElementSibling.setAttribute('aria-hidden', 'false')
    }
  }
  show(e){
    if(e.getAttribute('aria-expanded') === 'false') {
      e.setAttribute('aria-expanded', 'true')
      e.nextElementSibling.setAttribute('aria-hidden', 'false')
    }
  }
  devicePattern(){
    if(this.device === 'pc') {
      if(Model.info.isSP) {
        this.$el.btn.attr('aria-expanded', 'true')
        this.$el.content.attr('aria-hidden', 'false')
      } else {
        this.$el.btn.attr('aria-expanded', 'false')
        this.$el.content.attr('aria-hidden', 'true')
      }
    }
    if(this.device === 'sp') {
      if(Model.info.isSP) {
        this.$el.btn.attr('aria-expanded', 'false')
        this.$el.content.attr('aria-hidden', 'true')
      } else {
        this.$el.btn.attr('aria-expanded', 'true')
        this.$el.content.attr('aria-hidden', 'false')
      }
    }
  }
}
