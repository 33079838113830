export default class {
  constructor(element, config){
    this.element = element
    this.init()
    this.bindEvents()
  }
  init(){
    this.$el = {
      button : $(this.element).find('.role-has-sublist>a')
    }
  }
  bindEvents(){
    this.$el.button.on('click', (e)=>{
      e.preventDefault()
      let isOpen = String(e.currentTarget.nextElementSibling.getAttribute('aria-expanded'))
      if(isOpen !== 'false') {
        e.currentTarget.nextElementSibling.setAttribute('aria-expanded', 'false')
      } else {
        e.currentTarget.nextElementSibling.setAttribute('aria-expanded', 'true')
      }
    })
  }
}
