import Model from '../model'

export default class {
  constructor(element, config){
    this.element = element
    this.flag = false
    this.o = {
      img: undefined,
      btn: undefined,
      balloon: undefined,
      point: undefined,
      contentHeight: undefined
    }
    this.init()
    this.bindEvents()
  }
  init(){
    this.o = {
      img: this.element.querySelector('.r-img'),
      btn: this.element.querySelector('a'),
      balloon: this.element.querySelector('.r-balloon'),
      point: Model.state.scroll.bottom,
      contentHeight: undefined
    }
    this.scrollEvent()
  }
  bindEvents(){
    Model.event.on('ON_SCROLL', ()=>{
      this.scrollEvent()
    })
    this.o.btn.addEventListener('click', ()=>{
      if(!Model.info.isSP) this.click()
    })
    this.element.addEventListener('mouseover', ()=>{
      if(!Model.info.isSP) this.hover()
    })
    this.element.addEventListener('mouseleave', ()=>{
      if(!Model.info.isSP) this.leave()
    })
  }
  hover(){
    if(!this.flag && !this.element.classList.contains('is-hover') )  {
      this.element.classList.add('is-hover')
    }
  }
  leave(){
    if(this.element.classList.contains('is-hover') )  {
      this.element.classList.remove('is-hover')
      this.flag = false
    }
  }
  click(){
    this.flag = true
    this.leave()
  }
  scrollEvent(){
    this.o.point = Model.state.scroll.bottom
    this.o.contentHeight = document.body.clientHeight - document.querySelector('.l-footer__copy').clientHeight
    if(this.o.point > this.o.contentHeight) {
      this.element.classList.remove('is-fixed')
    } else {
      this.element.classList.add('is-fixed')
    }
    if(Model.state.scroll.moving) {
      this.element.classList.add('is-moving')
    } else {
      this.element.classList.remove('is-moving')
    }
  }
}
