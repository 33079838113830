import Model from '../model'
import Util from '../util'

export default class {
// -----------------------------------------------------------------
//
// @method Components
// -----------------------------------------------------------------
  constructor(element, config) {
    this.element = element || {}
    this.config = (config) ? config : null
    this.callbackname = null
    this.mid = [atob(this.element.getAttribute('data-mid'))]
    window.searchResultEq = this.searchResultEq || {}
    this.init()
  }
  init() {
    this.dataset()
  }
  dataset(){
    let param ={
      "mid": this.mid
    }
    window.loadEqMeta(param)
  }
  searchResultEq(result){
    var test = result.meta[0].thumbnail_url_ssl;
    document.querySelector('[data-module="get-jstream-thumbnail"] img').setAttribute('src', test);
  }
}
