import Model from '../model'

export default class {
  constructor(){
    this.el = {
      button: document.querySelector('[data-role=header-search-open-button]'),
      search: document.querySelector('[data-role=header-search]'),
      submit: document.querySelector('[data-role=header-search] [type="submit"]'),
      textfield: document.querySelector('[data-role=header-search-textfield]'),
      globalnav: document.querySelector('.l-header__nav__btn'),
      html: document.getElementsByTagName('html')[0]
    }
    if(this.el.button) {
      this.init()
      this.bindEvents()
    }
  }

  init(){

  }
  bindEvents(){
    this.el.button.addEventListener('click', (e)=>{
      e.stopPropagation()
      this.el.search.classList.add('is-open')
      this.el.textfield.focus()
      this.el.globalnav.setAttribute('aria-expanded', 'false')
      this.el.html.classList.remove('is-gnav-open')
    })
    this.el.submit.addEventListener('focus', (e)=>{
      e.stopPropagation()
      this.el.search.classList.add('is-open')
      this.el.submit.focus()
      this.el.textfield.focus()
    })
    this.el.textfield.addEventListener('blur', ()=>{
      this.el.search.classList.remove('is-open')
    })
  }

}
