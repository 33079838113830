import Model from '../model'

export default class {
  constructor(element, config){
    this.element = element
    this.data = newslist
    this.o = ''
    this.bindEvents()
    this.init()
  }
  init(){
    for(let i = 0; i < this.data.length; i = i+1){
      this.render(this.data[i])
    }
    this.newslisttab('year')
    this.newslisttab('category')
    this.element.innerHTML = this.o
    this.tabselect()
  }
  bindEvents(){
    // Model.event.on('ON_SCROLL', ()=>{
    //
    // })
  }
  newslisttab(category){
    let y = '';
    this.tab = `
      <select name="" id="" data-role="${category}">
    `
    for(let i = 0; i < this.data.length; i = i+1){
      if(y !== this.data[i][category]) {
        this.tab += `
          <option value="${this.data[i][category]}">${this.data[i][category]}</option>
        `
        y = this.data[i][category]
      }
    }
    this.tab += `</select>`
    $('[data-role=news-list-tab]').append(this.tab)

  }

  tabselect(){
    $('[data-role=news-list-tab]').find('select').on('change', (e)=>{
      let current = []
      $('[data-role=news-list-tab] select').each(function(){
        current.push($(this).find('option:selected').val())
      })
      let r = e.currentTarget.getAttribute('data-role')

      $(`[data-${r}]`).hide()

      let b = current.every(function(value){
        return value === r
      })
      if(b) $(`[data-${r}=${$(e.currentTarget).val()}]`).show()
    })
  }
  render(o){
    this.o += `
    <div data-year="${o.year}" data-category="${o.category}">
      <div><a href="${o.url}">${o.hdg}</a></div>
      <div>${o.date}</div>
      <div>${o.txt}</div>
    </div>
    `
  }
}
