

export default class {
  constructor(element, config){
    this.$el = {
      pager: $(element)
    }
    // this.init()
    // this.bindEvents()
  }
  bindEvents(){
    this.$el.pausebtn.on('click', ()=>{
      // this.pause()
    })
  }
  init(){
    this.nav = this.$el.pager.find('[data-role=pager-nav]')
    this.navItem = this.nav.find('li')
    this.navItemEllipsis = '<li class="role-itm is-disabled"><a>…</a></li>'
    this.navLength = this.navItem.length
    this.navItemFirst = this.nav.find('li:first')
    this.navItemLast = this.nav.find('li:last')
    this.navCurrent = this.navItem.index($('[aria-current=true]')) + 1
    this.navSlice = (this.navLength === this.navCurrent) ? this.navItem.slice(this.navCurrent-3,this.navCurrent) : (this.navCurrent === 1) ? this.navItem.slice(this.navCurrent-1,this.navCurrent+2) : this.navItem.slice(this.navCurrent-2,this.navCurrent+1)
    console.log(this.navItem);
    if(this.navLength < 5 || (this.navLength === 5 && this.navCurrent === 3)) {
      // ページ数4未満 またはページ数5の時カレント3
    } else
    if(this.navLength >= 5 && this.navCurrent < 4) {
      // ページ数5以上、カレント4未満
      (this.navCurrent !== 3) ? this.nav.empty() : this.nav.empty().append(this.navItemFirst)
      console.log(this.navSlice);
      this.navSlice.each(function(i,v){
        this.nav.append(v)
      })
      (this.navLength === 5 && this.navCurrent === 3) ? this.nav.append(this.navItemLast) : this.nav.append(this.navItemEllipsis).append(this.navItemLast)
    } else if(this.navLength - this.navCurrent < 3) {
      // ページ数5以上、カレントがページ数末尾から3未満
      this.nav.empty().append(this.navItemFirst).append(this.navItemEllipsis)
      this.navSlice.each(function(i,v){
        this.nav.append(v)
      })
      if(this.navLength - this.navCurrent === 2) this.nav.append(this.navItemLast)
    } else if(this.navLength >= 5) {
      // その他のページ数5以上
      this.nav.empty().append(this.navItemFirst).append(this.navItemEllipsis)
      this.navSlice.each(function(i,v){
        this.nav.append(v)
      })
      this.nav.append(this.navItemEllipsis).append(this.navItemLast)
    }
  }
}
