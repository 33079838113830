export default class {
  constructor(element, config){
    this.element = element
    this.config = (config) ? config : null
    this.init()
  }
  bindEvents(){
    this.element.addEventListener('click', (e)=>{
      e.preventDefault()
      this.print()
    })
  }
  init(){
    this.bindEvents()
  }
  print(){
    window.print()
    return false
  }
}
