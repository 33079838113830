import Model from './model'
import Getter from './model/getter'
// TODO: 未精査
import * as Polyfill from './polyfill'


import Module from './module'
import Components from './components'
import Util from './util'
// import Projects from './project/hoge'
import Dev from './dev'


new Getter()

document.addEventListener('DOMContentLoaded', ()=>{

  new Module()
  new Components()
  // new Projects()

  // if(document.querySelectorAll('.is-dummy')[0] || document.querySelectorAll('.is-dummy-replace')[0]) new Dev()

})
