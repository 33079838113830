import Model from '../model'
import Util from '../util'

export default class {
// -----------------------------------------------------------------
//
// @method Components.FilterSelected()
// -----------------------------------------------------------------
  constructor(element, config) {
    this.element = element
    this.config = (config) ? config : null
    this.$el = {
      view: undefined,
      select: undefined,
      btn: undefined,
      target: undefined
    }
    this.init()
  }

  init() {
    this.$el = {
      view: $(this.element).find('[data-role=filter-view]'),
      select: $(this.element).find('[data-role=filter-select]'),
      btn: $(this.element).find('[data-role=filter-btn] [data-value]'),
      target: $(this.element).find('[data-role=filter-target]')
    }
    this.bindEvents()
  }
  bindEvents() {
    this.$el.btn.on('click', (e)=>{
      this.selectValue = e.currentTarget.getAttribute('data-value')
      this.changeValue()
    })
    this.$el.select.on('change', (e)=>{
      this.selectValue = e.currentTarget.options[e.currentTarget.selectedIndex].value
      this.selectLink = e.currentTarget.options[e.currentTarget.selectedIndex].getAttribute('data-href')
      this.render()
      this.filter()
      if(this.selectLink) this.pageTransition()
    })
  }
  changeValue() {
    this.$el.select.val(this.selectValue)
    this.render()
    this.filter()
  }
  render(){
    this.$el.view.text(this.selectValue)
    this.$el.btn.removeClass('is-current')
    $(this.element).find('[data-value='+this.selectValue+']').addClass('is-current')
  }
  filter() {
    this.$el.target.find('[data-filter]')
      .attr('aria-hidden', 'true')
    this.$el.target.find('[data-filter='+this.selectValue+']')
      .attr('aria-hidden', 'false')
  }
  pageTransition() {
    location.href = this.selectLink
  }
}
