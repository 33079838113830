import Model from '../model'
import Util from '../util'

export default class {
  constructor(element, config){
    this.element = element
    this.config = config
    this.setting = {
      device: (Util.isKeyInObject(this.config,'device')) ? this.config["device"] : "common",
      pcslidenumber: (Util.isKeyInObject(this.config,'pc-slide-number')) ? parseInt(this.config["pc-slide-number"]) : 4,
      spslidenumber: (Util.isKeyInObject(this.config,'sp-slide-number')) ? parseInt(this.config["sp-slide-number"]) : 2,
      pcscrollnumber: (Util.isKeyInObject(this.config,'pc-scroll-number')) ? parseInt(this.config["pc-scroll-number"]) : 4,
      spscrollnumber: (Util.isKeyInObject(this.config,'sp-scroll-number')) ? parseInt(this.config["sp-scroll-number"]) : 2,
      fade: (Util.isKeyInObject(this.config,'fade')) ? (this.config["fade"] === "true") ? true : false : false,
      dots: (Util.isKeyInObject(this.config,'dots')) ? (this.config["dots"] === "true") ? true : false : false,
      autoplay: (Util.isKeyInObject(this.config,'autoplay')) ? (this.config["autoplay"] === "true") ? true : false : false,
      adaptiveHeight: (Util.isKeyInObject(this.config,'adaptiveHeight')) ? (this.config["adaptiveHeight"] === "true") ? true : false : false,
      variableWidth: (Util.isKeyInObject(this.config,'variableWidth')) ? (this.config["variableWidth"] === "true") ? true : false : false,
      replaceImage: (Util.isKeyInObject(this.config,'replace-devise-image')) ? (this.config["replace-devise-image"] === "true") ? true : false : true
    }
    this.$el = {
      carousel: $(this.element).find('.r-slider'),
      itm: ($(this.element).find('[data-role=itm]')[0]) ? $(this.element).find('[data-role=itm]') : $(this.element).find('.r-itm'),
      pausebtn: $(this.element).find('.r-slider__pausebtn'),
      image: undefined
    }
    this.$el.image = this.$el.itm.find('img')
    this.target = undefined
    this.slider = undefined

    this.slideNum = (this.$el.itm.length < this.setting.pcslidenumber) ? this.$el.itm.length : this.setting.pcslidenumber
    this.spSlideNum = (this.$el.itm.length < this.setting.spslidenumber) ? this.$el.itm.length : this.setting.spslidenumber
    // console.log(this.$el.itm.length)
    if(this.$el.itm.length <= 1) this.$el.carousel.addClass('is-itm-only')
    this.init()
    this.bindEvents()
  }
  bindEvents(){
    this.$el.pausebtn.on('click', ()=>{
      this.pause()
    })
    Model.event.on('ON_WINDOW_RESIZE', ()=>{
      if(this.slider) this.reinit()
    })
    Model.event.on('ON_CAROUSEL_STATE', ()=>{
      this.target = Model.state.carousel
      if(this.element.getAttribute('data-carouselid') === this.target.target) this.togo()
    })
    if(Util.isKeyInObject(this.config,'replace-deviceimage')) {
      Model.event.on('ON_WINDOW_RESIZE', ()=>{
        this.$el.image.each((i,el)=>{
          if(this.setting.replaceImage) this.judge(el)
        })
      })
    }
  }
  init(){

    if(Util.isKeyInObject(this.config,'replace-deviceimage')) {
      this.$el.image.each((i,el)=>{
        this.judge(el)
      })
    }
    this.slideshow()
  }
  slideshow(){
    this.option = {
      dots: (this.setting.dots === false) ? false : (this.setting.device === "sp") ? false : true,
      slidesToShow: this.slideNum,
      slidesToScroll: this.setting.pcscrollnumber,
      fade: this.setting.fade,
      autoplay: this.setting.autoplay,
      adaptiveHeight: this.setting.adaptiveHeight,
      variableWidth: this.setting.variableWidth,
      prevArrow: this.element.querySelector('.r-slider__prevarrow'),
      nextArrow: this.element.querySelector('.r-slider__nextarrow'),
      // variableWidth: (this.config["variableWidth"] === "false") ? false : true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            dots: (this.setting.dots === false) ? false : (this.setting.device === "pc") ? false : true,
            speed: 700,
            slidesToShow: this.spSlideNum,
            slidesToScroll: this.setting.spscrollnumber,
          }
        }
      ]
    }
    this.slider = this.$el.carousel.slick(this.option)
  }
  reinit() {
    this.slider.slick('setPosition')
  }
  judge(el){
    let getSrc = el.getAttribute('src'),
        setSrc = undefined
    if(Model.state.windowsize.w < 768) {
      setSrc = getSrc.replace(/_pc./g, '_sp.')
    } else {
      setSrc = getSrc.replace(/_sp./g, '_pc.')
    }
    el.setAttribute('src', setSrc)
  }
  pause(){
    if(this.$el.pausebtn.hasClass('is-pause')){
      this.slider.slick('slickPlay')
      this.$el.pausebtn.removeClass('is-pause')
    } else {
      this.slider.slick('slickPause')
      this.$el.pausebtn.addClass('is-pause')
    }
  }
  togo(){
    if(this.target) {
      this.slider.slick('slickSetOption', {'speed': 1})
      this.slider.slick('slickGoTo', this.target.number)
      this.slider.on('afterChange', ()=>{
        this.slider.slick('slickSetOption', {'speed': 700})
      })
    }
  }
}
