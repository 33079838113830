

export default class {
  constructor(element, config){
    this.$el = {
      carousel: $('[data-module=detail-carousel] .r-slider'),
      pausebtn: $('[data-module=detail-carousel] .r-slider__pausebtn'),
      indicator: $('[data-module=detail-carousel] [role="itm"]')
    }
    this.slider = undefined
    this.init()
    this.bindEvents()
  }
  bindEvents(){
    this.$el.pausebtn.on('click', ()=>{
      this.pause()
    })
    this.$el.indicator.on('click', (e)=>{
      this.move($(e.currentTarget).index())
    })
  }
  init(){
    this.slider = this.$el.carousel.slick({
      fade: true,
      speed: 700,
      adaptiveHeight: false,
      centerMode: true,
      prevArrow: '.r-slider__prevarrow',
      nextArrow: '.r-slider__nextarrow'
    })
  }
  pause(){
    if(this.$el.pausebtn.hasClass('is-pause')){
      this.slider.slick('slickPlay')
      this.$el.pausebtn.removeClass('is-pause')
    } else {
      this.slider.slick('slickPause')
      this.$el.pausebtn.addClass('is-pause')
    }
  }
  move(index){
    this.slider.slick('slickGoTo', index);
  }
}
