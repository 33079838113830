import ChangeViewport from './change-viewport'
import SmoothScroll from './smooth-scroll'
import TouchClass from './touch-class'
import KeydownTabClass from './keydown-tab-class'
import AddUAClass from './add-ua-class'
import TransitionSmoothScroll from './transition-smooth-scroll'

import HeaderFunction from './header'

export default class {
  constructor(){
    new ChangeViewport()
    new SmoothScroll()
    new TouchClass()
    new KeydownTabClass()
    new AddUAClass()

    new HeaderFunction()

    if(location.hash) new TransitionSmoothScroll()
  }
}
