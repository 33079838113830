import Model from '../model'
import Util from '../util'

export default class {
  constructor(element, config){
    this.element = element
    this.$el = {
      btn: $(this.element).find('.c-btn-list__itm__link'),
      btnCnt: $(this.element).find('.c-btn-list__itm__content'),
      btnSec: $(this.element).find('.r-recipe-detail-btn'),
      tag: $(this.element).find('.r-recipe-detail-tag'),
      itms: $(this.element).find('.c-tag-list__itms'),
      itm: $(this.element).find('.r-itm')
    }
    this.init()
    this.bindEvents()
  }
  bindEvents(){
    this.$el.btn.on('click', ()=>{
      //クリック時のイベント
      if(this.$el.tag.hasClass('r-active')){
        this.$el.tag.removeClass('r-active')
        this.$el.btnSec.removeClass('r-active')
        this.$el.btn.removeClass('r-up').addClass('r-down')
        this.$el.btnCnt.text('もっと見る')
      } else {
        this.$el.tag.addClass('r-active')
        this.$el.btnSec.addClass('r-active')
        this.$el.btn.addClass('r-up').removeClass('r-down')
        this.$el.btnCnt.text('閉じる')
      }
    })
    Model.event.on('ON_WINDOW_RESIZE', ()=>{
      //リサイズ時のイベント
      this.judge()
    })
  }
  init(){
    this.judge()
  }
  judge(){
    if(this.$el.itm.outerHeight(true) * 2 < this.$el.itms.height()) {
      this.$el.btnSec.removeClass('is-disused')
    } else {
      this.$el.btnSec.addClass('is-disused')
    }
  }
}
