import Model from '../model'
import Util from '../util'

export default class {
// -----------------------------------------------------------------
//
// @method Components.RenderProductsOthercategory()
// JSON読み込みリストへ変換
// -----------------------------------------------------------------
  constructor(element, config) {
    this.element = element
    this.config = (config) ? config : null
    this.type = (Util.isKeyInObject(this.config,'type')) ? this.config['type'] : 'header_products'

    this.el = {
      list: undefined,
      object: undefined,
      template: undefined
    }
    this.init()
  }

  init() {
    this.el = {
      list: undefined,
      object: Util.getJSON(`/json/${this.type}.json`)[0][this.type],
      template: ''
    }
    if(!this.el.object || this.el.object.length <= 0) this.renderNoResult()
    this.render()
    this.bindEvents()
  }
  bindEvents() {}
  renderNoResult() {
    this.element.remove()
    return false
  }
  render() {
    for(let i in this.el.object) {
      let img = (this.el.object[i].image !== undefined) ? `<span class="r-img"><img src="${this.el.object[i].image}" alt=""></span>` : '';
      let blank = (this.el.object[i].external === true) ? ' target="_blank"' : '';
      let classblank = (this.el.object[i].external === true) ? ' r-blank' : '';
      this.el.template += Util.escapeHTMLliteral`
        <li class="r-itm${classblank}"><a href="${this.el.object[i].url}"${blank}>${img}${this.el.object[i].name}</a></li>
      `
    }
    this.element.innerHTML = this.el.template
    return false
  }
}

