import Model from '../model'
import Util from '../util'

export default class {
// -----------------------------------------------------------------
//
// @method Components.HeaderProductsMenu()
// JSON読み込みリストへ変換
// -----------------------------------------------------------------
  constructor(element, config) {
    this.element = element
    this.config = (config) ? config : null
    this.type = (Util.isKeyInObject(this.config,'type')) ? this.config["type"] : '',
    this.domain = (window.header_products_domain) ? window.header_products_domain : '',
    this.getInner = (Util.isKeyInObject(this.config,'getinner')) ? this.config["getinner"] : false,
    this.el = {
      object: undefined,
      template: undefined
    }

    this.init()
  }

  init() {
    this.el = {
      object: (this.getInner !== true && !window.header_products) ? Util.getJSON(`/json/header_products.json`)[0]['header_products'] : window.header_products[0]['header_products'],
      template: ''
    }

    if(this.el.object) {
      if(this.type !== 'sp') {
        this.render()
      } else {
        this.renderSP()
      }
    }
    this.bindEvents()
  }
  bindEvents() {

  }
  render() {
    for(let i in this.el.object) {
      let blank = (this.el.object[i].external) ? ' target="_blank"' : '',
          blankClass = (this.el.object[i].external) ? ' r-blank' : ''
      // let categoryTemplate = (this.showCategory || this.category === 'all') ? `<span class="r-notice">${this.el.object[i].category}</span>` : ''
      this.el.template += `
        <li class="r-itm">
          <a href="${Util.escapeHTML(this.domain + this.el.object[i].url)}"${blank} class="${blankClass}" data-gtm="cojpmenulink">
            <figure class="r-img">
              <img src="${Util.escapeHTML(this.domain + this.el.object[i].image)}" alt="">
            </figure>
            <span class="r-hdg">${Util.escapeHTML(this.el.object[i].name)}</span>
          </a>
        </li>
      `
    }
    this.element.innerHTML = this.el.template
  }
  renderSP() {
    this.el.template += `
    <li class="l-gnav__sublist__itm">
      <a href="/products/" class="l-gnav__sublist__itm__link" data-gtm="cojpmenulink">商品情報トップ</a>
    </li>
    `
    for(let i in this.el.object) {
      let blank = (this.el.object[i].external) ? ' target="_blank"' : '',
          blankClass = (this.el.object[i].external) ? ' r-blank' : ''
      // let categoryTemplate = (this.showCategory || this.category === 'all') ? `<span class="r-notice">${this.el.object[i].category}</span>` : ''
      this.el.template += `
        <li class="l-gnav__sublist__itm">
          <a href="${Util.escapeHTML(this.domain + this.el.object[i].url)}"${blank}" class="l-gnav__sublist__itm__link${blankClass}" data-gtm="cojpmenulink">${Util.escapeHTML(this.el.object[i].name)}</a>
        </li>
      `
    }
    this.element.innerHTML = this.el.template
  }
}
