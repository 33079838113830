import Model from '../model'

export default class {
  constructor(){
    this.adjustLargeSmartPhone()
    this.adjustTablet()
    this.bindEvents()
  }

  bindEvents(){
    Model.event.on('ON_WINDOW_RESIZE', ()=>{
      this.adjustLargeSmartPhone()
    })
  }

// -----------------------------------------------------------------
//
// @method adjustTablet()
//
// -----------------------------------------------------------------

  adjustLargeSmartPhone(){
    if(Model.info.ua.Mobile){
      let vp = document.querySelector('[name=viewport]')
      if(Model.info.isSP) {
        vp.setAttribute('content','width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0')
      } else {
        vp.setAttribute('content','width=1200')
      }
    }
  }
  adjustTablet(){
    if(Model.info.ua.Tablet) {
      let vp = document.querySelector('[name=viewport]')
      vp.setAttribute('content','width=1200')
    }
  }

}
