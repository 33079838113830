import Model from '../model'

const Util = new class Util {

// -----------------------------------------------------------------
//
// @method Util.escapeHTML()
// @method Util.escapeHTMLliteral()
//
// -----------------------------------------------------------------

  escapeHTML(str) {
    str = String(str)
    str = str.replace(/&/g, '&amp;')
    str = str.replace(/</g, '&lt;')
    str = str.replace(/>/g, '&gt;')
    str = str.replace(/"/g, '&quot;')
    str = str.replace(/'/g, '&#39;')
    return str
  }

  escapeHTMLliteral() {
    var raw = String.raw.apply(null, arguments)
    var safe = raw.replace(/&/g, '&').replace(/>/g, '>').replace(/"/g, '"').replace(/'/g, '\'')
    return safe
  }

// -----------------------------------------------------------------
//
// @method Util.getCookie()
// Cookie取得
// -----------------------------------------------------------------

  getCookie(key) {
    var result = []
    var cookies = document.cookie
    if(cookies !== ''){
      var cookieArray = cookies.split(';')
      for(var i = 0; i < cookieArray.length; i++){
        var cookie = cookieArray[i].split('=')
        if(cookie[0].indexOf(key) !== -1) result = decodeURIComponent(cookie[1])
      }
    }
    if(result.length > 0) return result
  }


// -----------------------------------------------------------------
//
// @method Util.setCookie()
// Cookie設定
// -----------------------------------------------------------------

  setCookie(key, data, period) {
    var cookies = `${key}=${data};`
    var expire = new Date()
    expire.setTime( expire.getTime() + 1000 * 3600 * 24 * period)
    expire.toGMTString();
    cookies += (period) ? ` path=/; expires=${expire};` : ` path=/;`
    document.cookie = cookies
  }


// -----------------------------------------------------------------
//
// @method Util.getJSON()
// JSON取得
// -----------------------------------------------------------------

  getJSON(url){
    let Httpreq = new XMLHttpRequest()
        Httpreq.open("GET", url, false)
        Httpreq.send(null)
    return JSON.parse(Httpreq.responseText)
  }


// -----------------------------------------------------------------
//
// @method Util.getParam()
// getパラメータ取得
// -----------------------------------------------------------------

  getParam(){
    let arg = new Object
    let pair=location.search.substring(1).split('&')
    for(let i=0;pair[i];i++) {
        let kv = pair[i].split('=')
        arg[this.escapeHTML(kv[0])]=this.escapeHTML(kv[1])
    }
    return arg
  }

  // -----------------------------------------------------------------
  //
  // @method Util.isKeyInObject(obj, key)
  // data-config内 key判定
  // -----------------------------------------------------------------

  isKeyInObject(obj, key) {
    if(obj !== null && typeof obj === 'object') {
      return Object.keys(obj).some(v => v == key)
    } else {
      return false
    }
  }

  // -----------------------------------------------------------------
  //
  // @method Util.nextAll()
  // jQuery.nextAll関数
  // -----------------------------------------------------------------

  nextAll(node, selector) {
    var list = [];
    var next = node.nextElementSibling;

    while (next && next.nodeType === 1) {
      list.push(next);
      next = next.nextElementSibling;
    }

    if (selector) {
      var node = [].slice.call(document.querySelectorAll(selector));

      list = list.filter(function(item) {
        return node.indexOf(item) !== -1;
      });
    }

    return list;
  }

}



export default Util
