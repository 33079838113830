import Model from '../model'
import Util from '../util'

export default class {
// -----------------------------------------------------------------
//
// @method Components.RenderNoticesList()
// 重要なお知らせ JSON読み込みリストへ変換
// -----------------------------------------------------------------
  constructor(element, config) {
    this.element = element
    this.config = (config) ? config : null
    this.type = (Util.isKeyInObject(this.config,'type')) ? this.config['type'] : 'normal'
    this.category = (Util.isKeyInObject(this.config,'category')) ? this.config['category'] : 'emergency'
    this.number = (Util.isKeyInObject(this.config,'number')) ? parseInt(this.config['number']) : null
    this.el = {
      list: undefined,
      object: undefined,
      template: undefined
    }
    this.init()
  }

  init() {
    this.el = {
      list: undefined,
      object: Util.getJSON(`/_alias/json/notices/all.json`)[0][this.category],
      template: ''
    }

    if(this.number === null || this.el.object.length < this.number) this.number = this.el.object.length
    if(this.el.object) (this.type === 'topics') ? this.renderTopics() : (this.type === 'simple') ?  this.renderSimple() : this.render()
    this.bindEvents()
  }
  bindEvents() {

  }
  render() {
    for(let i in this.el.object) {
      this.el.template += Util.escapeHTMLliteral`
        <li class="r-itm">
          <a href="${this.el.object[i].link}" target="_blank">
            <p class="r-hdg">${this.el.object[i].title.replace(/@@/g,'')}</p>
          </a>
        </li>
      `
      if(i >= this.number - 1) {
        this.element.innerHTML = this.el.template
        return false
      }
    }
  }
  renderTopics() {
    if(this.el.object.length <= 0) {
      this.element.classList.add('u-none')
      return false
    }
    if(this.category === 'emergency_mid') {
      let mv = document.querySelectorAll('[data-module=mainvisual-carousel]')
      if(mv[0]) {
        for(let i=0; i<mv.length; i=i+1) {
          mv[i].classList.add('r-hasEmergency')
        }
      }
    }
    this.el.template = Util.escapeHTMLliteral`
      <div class="p-top-topics__ttl">
        <p class="p-top-topics__ttl__txt">重要な<br class="u-none--pc">お知らせ</p>
      </div>
      <div class="p-top-topics__topic">
    `
    for(let i in this.el.object) {
      this.el.template += Util.escapeHTMLliteral`
        <p class="p-top-topics__topic__txt">
          <a href="${this.el.object[i].link}" class="p-top-topics__topic__txt__link" target="_blank">${this.el.object[i].title.replace(/@@/g,'')}</a>
        </p>
      `
      if(i >= this.number - 1) {
        this.el.template += Util.escapeHTMLliteral`
         </div>
        `
        this.element.innerHTML = this.el.template
        return false
      }
    }
  }
  renderSimple() {
    for(let i in this.el.object) {
      this.el.template += Util.escapeHTMLliteral`
        <li class="r-itm">
          <a href="${this.el.object[i].link}" target="_blank">
            <span class="r-date">${this.el.object[i].date}</span>
            <p class="r-hdg">${this.el.object[i].title.replace(/@@/g,'')}</p>
          </a>
        </li>
      `
      if(i >= this.number - 1) {
        this.element.innerHTML = this.el.template
        return false
      }
    }
  }
}

