import Model from '../../model'

export default class {
  constructor() {
    this.isSP = false
    this.bindEvents()
    this.checkVP()
  }

  bindEvents() {
    Model.event.on('ON_WINDOW_RESIZE', ()=>{
      this.checkVP()
    })
  }

  checkVP() {
    if(Model.state.windowsize.w > 768) {
      this.isSP = false
    } else {
      this.isSP = true
    }
    Model.onCheckVP(this.isSP)
  }
}
