import Model from '../model'
import Util from '../util'

export default class {
// -----------------------------------------------------------------
//
// @method Components.FilterPulldown()
// プルダウンの選択に応じてtarget切り替え
// -----------------------------------------------------------------
  constructor(element, config) {
    this.element = element
    this.config = (config) ? config : null
    this.pagejump = (Util.isKeyInObject(this.config,'pagejump')) ? true : false
    this.multiFilter = (Util.isKeyInObject(this.config,'multifilter')) ? true : false
    this.$el = {
      select: undefined,
      view: undefined,
      target: undefined,
      state: undefined
    }
    this.init()
  }

  init() {
    this.$el = {
      select: $(this.element).find('[data-role=pulldown-data]'),
      view: $(this.element).find('[data-role=pulldown-view]'),
      state: $(this.element).find('[data-role=pulldown-selected]')
    }
    this.bindEvents()
  }
  bindEvents() {
    this.$el.select.on('change', ()=>{
      this.judge()
    })
    this.$el.state.on('click', (e)=>{
      this.togglePulldown()
    })
    $(document).on('click touchend', (e)=> {
      if (!$(e.target).closest(this.element).length) {
        this.closePulldown()
      }
    })
    this.$el.view.find('[data-value]').on('click', (e)=>{
      this.sendSelectedValue(e)
    })
  }
  togglePulldown(){
    if(this.$el.view.attr('aria-hidden') === 'true') {
      this.$el.view.attr('aria-hidden', 'false')
    } else {
      this.$el.view.attr('aria-hidden', 'true')
    }
  }
  closePulldown(){
    this.$el.view.attr('aria-hidden', 'true')
  }
  sendSelectedValue(e){
    let target = e.target.getAttribute('data-value')
    this.$el.select.find('option').removeAttr('selected')
    this.$el.select.find(`option[value=${target}]`).attr('selected', 'selected')
    this.$el.view.find('[data-value]').removeClass('is-current')
    $(e.target).addClass('is-current')
    this.judge()
    this.togglePulldown()
  }
  judge(){
    let val = this.$el.select.val(),
        txt = this.$el.select.find('option:selected').text()
    this.targetAll = $(`[data-pulldown-target]`)

    if(this.multiFilter) {
      this.targetAll.attr('aria-hidden', 'true')
      this.getMultiTarget(val, txt)
    } else {
      let target = $(`[data-pulldown-target=${val}]`)
      this.show(val, target, txt)
    }
  }
  getMultiTarget(val, txt){
    if(val === 'all') {
      this.show(val, txt)
    } else {
      for(let i = 0; i < this.targetAll.length; i = i+1) {
        let arr = this.targetAll[i].getAttribute('data-pulldown-target').split(',')
        for(let j in arr) {
          if(val === arr[j]) {
          let target = $(this.targetAll[i])
            this.show(val, txt, target, true)
          }
        }
      }
    }
  }
  show(val, txt, target, multi){
    if(!this.pagejump) {
      this.$el.state.text(txt)
      if(val === 'all') {
        this.targetAll.attr('aria-hidden', 'false')
      } else {
        if(!multi) this.targetAll.attr('aria-hidden', 'true')
        target.attr('aria-hidden', 'false')
      }
    } else {
      location.href = Util.escapeHTMLliteral`${val}`
    }
  }
}
