import Model from '../model'

export default class {
  constructor(element, config){
    this.element = element
    this.config = (config) ? config : null
    this.el = {
      base: this.element.querySelector('[data-role=base-item] .r-img'),
      target: this.element.querySelectorAll('[data-role=target-item] .r-img'),
      targetdetail: this.element.querySelectorAll('[data-role=target-item] .r-detail'),
      targettxt: this.element.querySelectorAll('[data-role=target-item] .r-txt'),
    }
    this.bindEvents()
  }
  init(){
    if(this.el.base && this.el.targettxt[0]) {
      this.reset()
      if(!Model.info.isSP) {
        this.adjust()
      }
    }
  }
  bindEvents(){
    window.addEventListener('load', ()=>{
      this.init()
    })
    Model.event.on('ON_WINDOW_RESIZE', ()=>{
      this.init()
    })
  }
  reset(){
    for(let i = 0; i < this.el.target.length; i = i+1) {
      this.el.targetdetail[i].style.height = 'auto'
    }
  }
  adjust(){
    let height = 0

    for(let i = 0; i < this.el.target.length; i = i+1) {
      if(height < this.el.target[i].clientHeight) height = this.el.target[i].clientHeight
    }

    for(let i = 0; i < this.el.targetdetail.length; i = i+1) {
      this.el.targetdetail[i].style.height = this.el.base.clientHeight - (height * 2) + 'px'
    }
  }
}
