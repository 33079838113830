import Model from '../../model'

export default class {
  constructor() {
    this.carousel = {
      target: undefined,
      number: undefined
    }
    this.bindEvents()
  }

  bindEvents() {
    document.addEventListener('click', (ev) => {
      let target = ev.target.getAttribute('data-carouseltarget')
      if(target) {
        let n = ev.target.getAttribute('data-carouselnumber')
        this.carousel.target = target
        this.carousel.number = n
        Model.onCarouseSlide(this.carousel)
      }
    })
  }

}
