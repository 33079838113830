import Model from '../model'
import Util from '../util'

// -----------------------------------------------------------------
//
// @method Form.changeRowtable()
// SP時のテーブル th / td 縦横入れ替え機能
// -----------------------------------------------------------------

export default class {
  constructor(element,config) {
    this.element = element
    this.init()
  }
  init(){
    this.table = $(this.element)
    this.row = this.table.find('tr')
    this.head = this.table.find('.c-tbl__head')
    this.array = []
    this.render()
  }
  bindEvents(head,data){
    Model.event.on('ON_WINDOW_RESIZE', ()=>{
      this.checkHeight(head,data)
    })
  }
  render(){
    this.head.each((n,el)=> {
      this.array.push(el.textContent.replace(/\n/g,'').replace(/\s/g,''))
    })
    $.when.apply($, this.array).done((n)=>{
      this.row.each((n,el)=> {
      let data = $(el).find('td')
        data.each((n,el)=> {
          if(el.textContent !== '') {
            let clonehead = document.createElement('div')
            clonehead.classList.add('c-tbl__clonehead')
            clonehead.textContent = Util.escapeHTML(this.array[n])
            el.appendChild(clonehead)
            this.checkHeight(clonehead, el)
            this.bindEvents(clonehead, el)
          }
        })
      })
    })
  }
  checkHeight(head,data){
    if(Model.info.isSP){
      let headHeight = (100 / window.innerWidth * head.clientHeight + 1)
      head.classList.add('is-render')
      let dataHeight = (100 / window.innerWidth * data.clientHeight)
      if(dataHeight <= headHeight) {
        data.style.minHeight = headHeight + 'vw'
      } else {
        data.style.minHeight = dataHeight + 'vw'
      }
    } else {
      data.style.minHeight = '1px'
    }
  }
}
