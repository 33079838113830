import Model from '../model'

export default class {
// -----------------------------------------------------------------
//
// @method Components.replaceDeviceimage()
// ビューポート判定でPC / SPの画像差し替え
// -----------------------------------------------------------------
  constructor(element, config) {
    this.element = element
    this.getSrc = this.element.getAttribute('src')
    this.setSrc = undefined
    this.init()
  }

  init() {
    this.judge()
    Model.event.on('ON_WINDOW_RESIZE', ()=>{
      this.judge()
    })
  }

  judge(){
    if(Model.state.windowsize.w < 768) {
      this.setSrc = this.getSrc.replace(/_pc./g, '_sp.')
    } else {
      this.setSrc = this.getSrc.replace(/_sp./g, '_pc.')
    }
    this.element.setAttribute('src', this.setSrc)
  }


}
