export default class {
  constructor(element, config){
    this.element = element
    this.init()
    this.bindEvents()
  }
  init(){
    this.$el = {
      button : $(this.element).find('select')
    }
  }
  bindEvents(){
    this.$el.button.on('change', (e)=>{
      location.href = e.currentTarget.value
    })
  }
}
