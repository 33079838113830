import Model from '../model'
import Util from '../util'


export default class {
  constructor(element, config){
    this.element = element
    this.config = (config) ? config : null
    this.$el = {
      carousel: $(this.element).find('.r-slider'),
      pausebtn: $(this.element).find('.r-slider__pausebtn'),
      image: $(this.element).find('.r-itm img')
    }
    this.fade = (Util.isKeyInObject(this.config,'fade')) ? (this.config.fade === 'false') ? false : true : true
    this.target = undefined
    this.slider = undefined
    this.init()
    this.bindEvents()
  }
  bindEvents(){
    this.$el.pausebtn.on('click', ()=>{
      this.pause()
    })
    Model.event.on('ON_CAROUSEL_STATE', ()=>{
      this.target = Model.state.carousel
      if(this.element.getAttribute('data-carouselid') === this.target.target) this.togo()
    })
    if(Util.isKeyInObject(this.config,'replace-deviceimage')) {
      Model.event.on('ON_WINDOW_RESIZE', ()=>{
        this.$el.image.each((i,el)=>{
          this.judge(el)
        })
      })
    }
  }
  init(){
    if(Util.isKeyInObject(this.config,'replace-deviceimage')) {
      this.$el.image.each((i,el)=>{
        this.judge(el)
      })
    }
    if(Util.isKeyInObject(this.config,'is-centermode')) {
      this.slider = this.$el.carousel.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        centerMode: true,
        autoplay: true,
        fade: (!this.fade) ? false : true,
        variableWidth: (this.fade) ? false : true,
        speed: (this.fade) ? 1200 : 700,
        autoplaySpeed: (this.fade) ? 3000 : 5000,
        dots: true,
        prevArrow: this.element.querySelector('.r-slider__prevarrow'),
        nextArrow: this.element.querySelector('.r-slider__nextarrow'),
        responsive: [
          {
            breakpoint: 768,
            settings: {
            }
          }
        ]
      })
    } else {
      this.slider = this.$el.carousel.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        fade: (!this.fade) ? false : true,
        variableWidth: (this.fade) ? false : true,
        speed: (this.fade) ? 1200 : 700,
        autoplaySpeed: (this.fade) ? 3000 : 5000,
        dots: true,
        prevArrow: this.element.querySelector('.r-slider__prevarrow'),
        nextArrow: this.element.querySelector('.r-slider__nextarrow'),
        responsive: [
          {
            breakpoint: 768,
            settings: {
              variableWidth: (this.fade) ? false : true,
              centerMode: false
            }
          }
        ]
      })
    }
  }
  judge(el){
    let getSrc = el.getAttribute('src'),
        setSrc = undefined
    if(Model.state.windowsize.w < 768) {
      setSrc = getSrc.replace(/_pc./g, '_sp.')
    } else {
      setSrc = getSrc.replace(/_sp./g, '_pc.')
    }
    el.setAttribute('src', setSrc)
  }
  pause(){
    if(this.$el.pausebtn.hasClass('is-pause')){
      this.slider.slick('slickPlay')
      this.$el.pausebtn.removeClass('is-pause')
    } else {
      this.slider.slick('slickPause')
      this.$el.pausebtn.addClass('is-pause')
    }
  }
  togo(){
    if(this.target) {
      this.slider.slick('slickSetOption', {'speed': 1})
      this.slider.slick('slickGoTo', this.target.number)
      this.slider.on('afterChange', ()=>{
        this.slider.slick('slickSetOption', {'speed': 700})
      })
    }
  }
}
