export default class {
  constructor(element, config){
    this.$el = {
      tabs: $(element)
    }
    this.$el.tablist = this.$el.tabs.find('[role=tablist]')
    this.$el.tabpanel = this.$el.tabs.find('[role=tabpanel]')
    this.$el.tab = this.$el.tabs.find('[role=tab]')
    this.bindEvents()
  }
  bindEvents(){
    this.$el.tab.on('click', (e)=>{
      this.tabSwitch($(e.currentTarget))
    })
  }
  tabSwitch(e){
    let number = e.attr('aria-controls'),
        target = e.attr('aria-labeledby')
        this.$el.tab.removeClass('is-active')
        $('[aria-controls='+number+']').addClass('is-active')
        this.$el.tabpanel.attr('aria-hidden', 'true')
        $('[aria-labeledby=' + number + ']').attr('aria-hidden', 'false')
  }
}
